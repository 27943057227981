@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Sharp');

:root{
  --color-primary: #61aefb;
  --color-danger: #FF0060;
  --color-success: #1B9C85;
  --color-warning: #F7D060;
  --color-white: #fff;
  --color-info-dark: #7d8da1;
  --color-dark: #363949;
  --color-light: rgba(132, 139, 200, 0.18);
  --color-dark-variant: #677483;
  --color-background: #f6f6f9;

  --card-border-radius: 2rem;
  --border-radius-1: 0.4rem;
  --border-radius-2: 1.2rem;

  --card-padding: 1.8rem;
  --padding-1: 1.2rem;

  --box-shadow: 0 2rem 3rem var(--color-light);
}

.dark-mode-variables{
  --color-background: #181a1e;
  --color-white: #202528;
  --color-dark: #edeffd;
  --color-dark-variant: #a3bdcc;
  --color-light: rgba(0,0,0,0.4);
  --box-shadow: 0 2rem 3rem var(--color-light);
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  appearance: none;
  border: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

html {
  font-size: 14px;
}

.App {
  width: 100vw;
  height: 100vh;
  font-family: sans-serif;
  font-size: 0.88rem;
  background: var(--color-background);
  user-select: none;
  overflow-x: hidden;
  color: var(--color-dark);
}

a {
  color: var(--color-dark);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.container {
  display: grid;
  width: 96%;
  margin: 0 auto;
  gap: 1.8rem;
  grid-template-columns: 14rem auto 23rem;
}

.App-header {
  background-color: var(--color-dark);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

p {
  color: var(--color-dark-variant);
}

b {color: var(--color-dark);}

small {
  font-size: 0.75rem;
}

.div {
  color: var(--color-primary);
  margin-top: 10px;
  border-radius: 10px;
}

.text-muted {
  color: var(--color-info-dark);
}

.primary {
  color: var(--color-primary);
}

.danger {
  color: var(--color-danger);
}

.success {
  color: var(--color-success);
}

.warning {
  color: var(--color-warning);
}

.App-link {
  color: var(--color-primary);
  text-decoration-line: none;
}